<template>
    <div class="outerBox">
		<div class="lineChart" :id="'lineChart'+keyIndex"></div>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	width: 100%;
	height: 100%;

	.lineChart {
		height: 400%;
		width: 400%;
		transform: scale(0.25);
		transform-origin: 0 0;
	}

}
</style>

<script>
export default {
	props:{
		unit:{
			type: String
		},
		dataInput:{
			type: Array
		},
		tagName: {
			type: String
		},
		keyName: {
			type: String
		}
	},
	data(){
		return {
			lineChartEl: null,
			yUnit: '',
			dataArray:[],
			tagname: '',
			keyIndex: '',
			lineChartFontSize: 18,
			lineChartCfg: {
				tooltip: {
					trigger: 'axis',
					extraCssText: `
						background: linear-gradient(90deg, rgba(72,64,42,1) 0%, rgba(61,61,61,1) 100%);
						border: 2px solid;
						border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
						border-radius: 0;
						height: 11.84vh;
						display: flex;
						align-items: center;
						padding: 0
					`,
					position: function (pt) {
					return [pt[0], pt[1]];
					},
					formatter:(a)=>{
						return `
							<span style="
								color: #fff;
								font-size: 5.2vh;
								line-height: 11.84vh;
								vertical-align: top;
								margin-left: 1em;
								font-family: 'pingfangSc', sans-serif;
								font-weight: 400
							">${this.tagname}</span>
							<span style="
								font-size: 5.92vh;
								line-height: 11.84vh;
								vertical-align: top;
								font-family: 'titleFont', sans-serif;
								font-weight: 400;
								background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
								margin-left: 1em;
								margin-right: 1em
							">${a[0].value}</span>
						`;
					},
					axisPointer: {
						show: true,
						trigger: 'axis',
						type: 'line',
						lineStyle: {
							type: 'solid',
							width: 4,
							shadowBlur: 40,
							shadowColor: '#ffd03b',
							color: {
								type: 'linear',
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
									offset: 0,
									color: 'rgba(255, 208, 59, 0)'
									},
									{
									offset: 0.5,
									color: 'rgba(255, 208, 59, 1)'
									},
									{
									offset: 1,
									color: 'rgba(255, 208, 59, 0)'
									}
								],
								global: false
							}
						},
						z: 1
					}
				},
				grid: {
					left: '2%',
					right: '8%',
					bottom: '0',
					top: '5%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: (()=>{
						let d = this.dataInput;
						let arr = [];
						for(let i = 0;i < d.length;i++){
							arr.push(d[i].date);
						}
						return arr;
					})(),
					boundaryGap: false,
					axisLine: {
						lineStyle: {
							color: 'rgba(243,184,5,0.2)',
							type: 'dashed'
						}
					},
					axisTick: {
						show: false
					},
					axisLabel: {
						color: '#fff',
						fontSize: 36,
						lineHeight: 100
					}
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					splitLine: {
						lineStyle: {
							color: 'rgba(243,184,5,0.2)',
							width: 3,
							type: 'dashed'
						}
					},
					splitNumber: 4,
					axisLabel: {
						color: '#fff',
						fontSize: 36,
						formatter: (a) => {
							if (a >= 1000) {
							a /= 1000;
							a += 'k';
							}
							if(this.yUnit && a != 0){
								a += this.yUnit;
							}
							return a+'';
						}
					}
				},
				series: [
					{
						data: (()=>{
							let d = this.dataInput;
							let arr = [];
							for(let i = 0;i < d.length;i++){
								arr.push(d[i].value);
							}
							return arr;
						})(),
						type: 'line',
						smooth: true,
						areaStyle: {
							opacity: '0.2',
							color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [
								{
								offset: 0,
								color: '#16fff5'
								},
								{
								offset: 0.6,
								color: '#ffd03b'
								},
								{
								offset: 1,
								color: '#ff3b3b'
								}
							],
							global: false
							}
						},
						lineStyle: {
							color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 1,
							y2: 0,
							colorStops: [
								{
								offset: 0,
								color: '#ff3b3b'
								},
								{
								offset: 0.5,
								color: '#ffd03b'
								},
								{
								offset: 1,
								color: '#85faf4'
								}
							],
							global: false
							},
							width: 16
						},
						itemStyle: {
							normal: {
							color: '#ffd03b',
							borderWidth: 8,
							borderColor: '#0b0d07'
							}
						},
						symbol: 'circle',
						symbolSize: 24,
						showSymbol: false
					}
				]
			}
		}
	},
	mounted(){
		this.tagname = this.tagName;
		this.yUnit = this.unit;
		this.dataArray = this.dataInput;
		this.keyIndex = this.keyName;
		let timeoutId = setTimeout(()=>{
			this.setLineChart();
			clearTimeout(timeoutId);
		},300)
		
		
		return false;
	},
	methods:{
		setLineChart(){
			this.lineChartEl = this.$echarts(document.getElementById('lineChart'+this.keyName));
			this.lineChartEl.setOption(this.lineChartCfg);
		}
	}
}
</script>

